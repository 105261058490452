export default {
  title: 'Forward College Aptitude Test',
  description:
    'Forward College Aptitude Test is designed to measure your abilities to learn and apply the skills necessary to be a successful software engineer.',
  openGraph: {
    type: 'website',
    locale: 'en',
    url: 'https://aptitude.forward.edu.my/',
    site_name: 'Forward College Aptitude Test',
    images: [{ url: 'https://uploads-ssl.webflow.com/5f0ea923c59632f37c59e34e/5f28f61d61f5b4f2510420e7_Dev-2.jpg' }]
  }
}
